import { Text, VStack } from '@chakra-ui/layout'
import React from 'react'
import { Helmet } from 'react-helmet';

import DrawButton from '../../../../components/DrawButton'
// import DrawArrowButton from '../../../../components/DrawArrowButton';
import { responsive } from '../../../../components/ThemeProvider/theme'
import FullpageHeight from '../../../../components/FullpageHeight';

const options = [
  { label: '單人版', value: 'solo', to: `/q/love-languages/lover/solo/start/` },
  { label: '雙人版', value: 'dual', to: `/q/love-languages/lover/dual/` },
]

const Mode = () => {
  return (
    <FullpageHeight maxWidth="container.sm">
      <Helmet>
        <title>選擇測驗類型 - 愛之語測驗愛情版</title>
      </Helmet>
      <VStack
        alignItems="flex-start"
        justifyContent="center"
        color="love.text"
        fontSize={responsive('1.125rem', '1.25rem')}
        px="2"
        py="4"
        flex="1"
      >
        {/* <DrawArrowButton color="love.text" onClick={() => window.history.back()} /> */}
        {/* <Text letterSpacing="wider" fontWeight="900">請選擇測驗類型</Text> */}
        <Text fontWeight="bold">為了讓更多愛侶幸福，我們提供「雙人分析版」，若兩人都完成測驗，除了各自的「個人分析結果」，將額外提供兩人的相處建議及提醒！</Text>
        <Text>＊請注意，若一開始選單人版，想做雙人分析則需重做一次測驗</Text>
        <VStack pt="8" w="full" spacing="1.25em">
          {options.map(opt => (
            <DrawButton
              key={opt.value}
              to={opt.to}
              fontWeight="700"
              fontSize={responsive('1em', '1.125em')}
            >
              {opt.label}
            </DrawButton>
          ))}
        </VStack>
      </VStack>
    </FullpageHeight>
  )
}
export default Mode
